<template>
  <v-card>
    <v-card-text class="df-flex-m df-flex-col">
      <div class="df-flex-m df-flex-col">
        <div class="date-quote">
          <h4>
            <b>{{ quotation.date.formatDate() }}</b>
          </h4>
        </div>
        <div>
          <span class="currency-brl mr-1"> R$ </span>
          <span class="brl-value">{{ formatPrice(quotation.price)[0] }}</span>
          <span class="brl-value-cents">
            ,{{ formatPrice(quotation.price)[1] }}&nbsp;&nbsp;
          </span>
          <span class="acronym-bag">/sc&nbsp;&nbsp;</span>
          <span>
            <v-icon
              v-if="quotation.percentage_variation >= 0"
              x-small
              color="green"
              >mdi-arrow-up</v-icon
            >
            <v-icon v-else x-small color="red">mdi-arrow-down</v-icon>
          </span>
          <span class="percentage-variation">
            &nbsp;{{ porcentageVariation }}%
          </span>
          <div class="city-country">
            {{ quotation.name }}
          </div>
        </div>
      </div>
      <div class="df-flex-m">
        <div class="df-col-5 df-col-12-t df-col-12-m">
          <v-select
            v-model="crop"
            hide-details
            item-value="id"
            outlined
            :item-text="(item) => $t(item.messageKey)"
            :items="enabledCrops"
            :label="$t('crop.crop')"
            @change="getQuotations(crop)"
          />
        </div>
        <div class="df-col-7 df-col-12-t df-col-12-m">
          <v-select
            v-model="quotation"
            hide-details
            item-text="name"
            item-value="id"
            outlined
            return-object
            :items="quotationsSelect"
            :label="$t('square')"
          />
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import api from '@/services/api'
import { mapGetters } from 'vuex'
import { currentLanguage } from '@/language'

export default {
  name: 'PhysicalMarketCard',

  data() {
    return {
      currentLanguage,
      quotations: [],
      quotation: {
        id: null,
        name: null,
        percentage_variation: 0,
        date: new Date().toLocaleDateString(),
      },
      crop: 1,
    }
  },

  watch: {
    quotations(val, old) {
      if (val !== old) {
        this.quotation = val[0]
      }
    },
  },

  mounted() {
    this.crop = this.enabledCrops?.length ? this.enabledCrops[0] : null
    this.getQuotations(this.crop.id)
  },

  computed: {
    ...mapGetters('seasonsCrops', ['enabledCrops']),
    quotationsSelect() {
      return [...this.quotations]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((quotation) => ({
          ...quotation,
          name: quotation.name
            .split(' ')
            .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
            .join(' '),
        }))
    },
    porcentageVariation() {
      return this.quotation.percentage_variation
        ? this.quotation.percentage_variation.toFixed(1).replace('-', '')
        : 0
    },
  },

  methods: {
    async getQuotations(cropId) {
      const response = await api.quotations.getAllPhysicalMarket(cropId)
      this.quotations = response.data
    },
    formatPrice(price) {
      if (this.currentLanguage === 'pt') {
        return this.$currency(price).replace('R$', '').split(',')
      }

      return this.$currency(price).replace('$', ' ').split('.')
    },
  },
}
</script>

<style scoped>
.date-quote,
.percentage-variation {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 700;
}
.currency-brl,
.brl-value,
.city-country,
.acronym-bag,
.brl-value-cents {
  font-family: Rubik, sans-serif;
  font-style: normal;
}
.brl-value,
.brl-value-cents {
  color: #1a1a1a;
}
.date-quote,
.brl-value-cents {
  text-transform: uppercase;
}
.city-country {
  text-transform: capitalize;
}
.date-quote {
  font-size: 10px;
  line-height: 15px;
  color: #5b6459;
}
.currency-brl {
  font-weight: 400;
  font-size: 19px;
  line-height: 20px;
  color: #aab2a9;
}
.brl-value {
  font-weight: 700;
  font-size: 28px;
  line-height: 18px;
  margin-left: -5px;
}
.acronym-bag {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #aab2a9;
}
.city-country {
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  color: #797979;
}
.brl-value-cents {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}
.percentage-variation {
  font-size: 12px;
  line-height: 18px;
}
::v-deep .v-sheet.v-card {
  box-shadow: none !important;
}
</style>
