<template>
  <v-dialog
    :value="isOpen"
    width="unset"
    max-width="430px"
    @click:outside="closeModal"
  >
    <v-card class="delete-modal">
      <v-card-text
        class="d-flex flex-column align-center delete-modal__text px-6 py-8"
      >
        <v-img alt="warning" width="66" src="icons/ic-warning-green.svg" />
        <div class="d-flex flex-column align-center modal__message">
          <h2>{{ message }}</h2>
          <p>{{ $t('Modal.delete.cant_undo_message') }}</p>
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions class="d-flex justify-end modal__actions px-6 py-4">
        <v-btn class="btn-back" height="auto" plain text @click="closeModal">
          {{ $t('back') }}
        </v-btn>
        <v-btn
          class="btn-action ml-0"
          height="auto"
          plain
          text
          @click="action"
          >{{ $t('excluir') }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteModal',

  props: {
    action: {
      required: true,
      type: Function,
    },
    isOpen: {
      required: true,
      type: Boolean,
    },
    message: {
      default: '',
      type: String,
    },
  },

  methods: {
    closeModal() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.delete-modal {
  .delete-modal__text {
    gap: 16px;

    .modal__message {
      gap: 8px;

      h2 {
        color: #071505;
        font-family: 'Rubik';
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        text-align: center;

        &:first-letter {
          text-transform: capitalize;
        }
      }
      p {
        color: #071505;
        font-family: 'Rubik';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        &:first-letter {
          text-transform: capitalize;
        }
      }
    }
  }
  .modal__actions {
    gap: 16px;

    .btn-back {
      background-color: #ffffff;
      border: 1px solid #e6e9e6;
      padding: 16px 12px;
    }
    .btn-action {
      background-color: #f23528;
      padding: 16px 12px;
    }
  }
}
::v-deep .btn-back .v-btn__content,
::v-deep .btn-action .v-btn__content {
  font-family: 'Rubik';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #1a2b46;
  text-transform: capitalize;
  opacity: 1 !important;
}
::v-deep .btn-action .v-btn__content {
  color: #ffffff;
}
</style>
