<script>
import ModalFazenda from '@/pages/fazendas/ModalFazenda.vue'
import PermissionMixin from '@/components/Permission/PermissionMixin.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'HeaderNoFarms',

  mixins: [PermissionMixin],

  components: {
    ModalFazenda,
  },

  data() {
    return {
      dialogModalFarm: false,
    }
  },

  computed: {
    ...mapGetters('user', ['currentUser']),
    ...mapGetters('farms', ['currentFarm', 'currentFarmId']),
  },

  methods: {
    ...mapActions('farms', ['fetchFarms', 'firstFarmLoad', 'hasNoFarms']),
    createFarm() {
      this.dialogModalFarm = true
    },
    async reloadFarmsList() {
      if (this.hasNoFarms) {
        await this.firstFarmLoad()
      } else {
        await this.fetchFarms()
      }
      this.dialogModalFarm = false
    },
  },
}
</script>

<template>
  <div class="header-no-farms">
    <v-card class="header__image" height="240">
      <img
        alt="no-farm"
        class="image"
        src="img/backgrounds/img-header-home-no-farms-1920.webp"
        srcset="
          img/backgrounds/img-header-home-no-farms-768.webp   768w,
          img/backgrounds/img-header-home-no-farms-1264.webp 1264w,
          img/backgrounds/img-header-home-no-farms-1920.webp 1920w
        "
        sizes="
          (max-width: 768px) 768px,
          (max-width: 1264px) 1264px,
          (max-width: 1920px) 1920px,
          1920px
        "
      />
      <div class="df-flex-sm df-flex-col image__title">
        <h1>
          {{ $t('Home.header.hello_user', [currentUser.name]) }} Digifarmz,
        </h1>
        <div class="d-flex flex-column">
          <h2>{{ $t('fazenda.NoFarms.welcome_no_farms') }}</h2>
          <h2 class="font-weight-bold">
            {{ $t('fazenda.NoFarms.welcome_no_farms_bold') }}
          </h2>
        </div>
      </div>
    </v-card>
    <v-row justify="center" class="add-farm-container">
      <v-col cols="12" md="4" sm="12" xl="3">
        <v-card @click="createFarm" class="add-card-create-farm">
          <v-row justify="center">
            <v-col md="12" sm="4" class="add-icon-create-farm">
              <v-img
                alt="tractor"
                class="add-image-tractor"
                max-width="90px"
                src="icons/ic-tractor-icon.svg"
              />
            </v-col>
            <v-col md="12" sm="8">
              <div class="add-description-action">
                <v-card-text class="add-title-main">
                  {{ $t('fazenda.create_farm') }}
                </v-card-text>
              </div>
              <div class="add-subtitle-main">
                <v-card-subtitle
                  class="add-farm-subtitle"
                  v-html="$t('fazenda.manual_create_farm')"
                >
                </v-card-subtitle>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="12" xl="3" v-show="false">
        <v-card
          link
          to="/integracoes/fieldview"
          class="add-card-import-field-view"
        >
          <v-row justify="center">
            <v-col md="12" sm="4" class="add-icon-field-view">
              <v-img
                alt="fieldview"
                class="add-size-icon-field-view"
                src="img/logos/logo-fieldview.svg"
              />
            </v-col>
            <v-col md="12" sm="8">
              <div class="add-col-hello">
                <v-card-text
                  class="add-title-main"
                  v-html="$t('Home.header.fieldview_import')"
                ></v-card-text>
              </div>
              <div class="add-col-hello">
                <v-card-subtitle
                  class="add-farm-subtitle"
                  v-html="$t('Home.header.conditional_import_farms_and_plots')"
                >
                </v-card-subtitle>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <modal-fazenda
      v-if="dialogModalFarm"
      :dialog="dialogModalFarm"
      :farm-id="currentFarmId"
      @close="reloadFarmsList"
    />
  </div>
</template>

<style lang="scss" scoped>
.header-no-farms {
  max-width: 1440px;
  margin: 0px auto;
  padding: 0px 0px 40px 0px;

  .header__image {
    position: relative;
    border-radius: 0px 0px 8px 8px;
    overflow: hidden;

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
    .image__title {
      position: absolute;
      top: calc(50% - 20px);
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 100%;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 500;
      color: #ffffff;
      text-align: center;

      @include d(m) {
        padding: 0px 12px;
      }
      h1 {
        color: #62cc70;
        font-size: 16px;
        line-height: 20px;

        @include d(m) {
          font-size: 14px;
          line-height: 16px;
        }
      }
      h2 {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
}
.location-state,
.add-col-hello-user {
  color: #62cc70;
}
.add-description-no-farms,
.add-title-main,
.add-col-hello-user {
  font-family: Rubik;
}
.location-state {
  margin: 54px 0px 0px -55px;
}
.add-col-hello {
  text-align: center;
}
.add-description-no-farms {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #fff;
}
.add-col-hello-user {
  margin-top: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
.add-card-create-farm {
  padding-top: 21px;
  padding-bottom: 28px;
}
.add-image-tractor {
  width: 92px;
  margin-inline: auto;
}
.add-farm-container {
  margin: -40px 100px 0px 100px;
}
.add-farm-subtitle {
  margin: -28px 0px 0px 0px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}
.add-description-action {
  text-align: center;
}
.add-space-title {
  margin-top: -7px;
}
.add-title-main {
  margin-top: -7px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}
.add-subtitle-main {
  text-align: center;
}
.add-size-icon-folder {
  width: 81px;
  margin-inline: auto;
}
.add-size-icon-field-view {
  width: 92px;
  margin-inline: auto;
}
.add-card-import-field-view {
  padding-top: 4px;
  padding-bottom: 8px;
}
.add-icon-field-view {
  margin-top: 20px;
}
@media (min-width: 1904px) {
  .add-col-hello-user {
    margin-top: 100px;
    font-size: 20px;
    line-height: 26px;
  }
  .add-description-no-farms {
    font-size: 34px;
  }
}

@media (max-width: 960px) {
  .add-farm-container {
    margin: -40px 0 0 0;
  }
  .add-description-no-farms {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
  }
  .add-col-hello-user {
    font-weight: 400;
    font-size: 10px;
    line-height: 24px;
  }
  .add-space-title {
    margin-top: 16px;
  }

  @media (max-width: 780px) {
    .add-farm-container {
      margin: -30px 0 0 0;
    }
    .add-col-hello-user {
      margin-top: 26px;
    }
    .add-title-main {
      text-align: initial;
    }
    .add-subtitle-main {
      text-align: initial;
    }
    .add-farm-subtitle {
      text-align: initial;
    }
  }

  @media (max-width: 440px) {
    .add-icon-create-farm {
      margin-right: -70px;
    }
    .add-icon-field-view {
      margin-top: 11px;
      margin-right: -70px;
    }
  }
}
</style>
